export const debounce = (fn: Function, time = 0) => {
  let timeoutId: number | undefined;

  return (...args: any[]) => {
    if (timeoutId) {
      window.clearTimeout(timeoutId);
    }
    timeoutId = window.setTimeout(() => {
      timeoutId = undefined;
      fn(...args);
    }, time);
  };
};
