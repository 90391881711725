<script lang="ts">
import { v4 as uuid } from 'uuid';
import { vMaska } from 'maska/vue';
import { type MaskType, type MaskTokens, type MaskOptions } from 'maska';
import { injectErrorContext } from '~/components/ErrorContext.vue';
import { debounce } from '~/lib/debounce';

// from React's types
export type HTMLInputTypeAttribute =
  | 'email'
  | 'hidden'
  | 'number'
  | 'password'
  | 'search'
  | 'tel'
  | 'text';
type Props = {
  modelValue?: string;
  useChangeEvent?: boolean;
  debounceTime?: number;
  theme: 'light' | 'dark';
  type?: HTMLInputTypeAttribute;
  label?: string;
  hasValidation?: boolean;
  hasError?: boolean;
  errorMessage?: string;
  hasWarning?: boolean;
  warningMessage?: string;
  mask?: MaskType;
  maskTokens?: MaskTokens;
  hideIncrements?: boolean;
  formatMoney?: boolean;
  currency?: string;
  fractionDigits?: number;
  as?: 'input' | 'textarea';
  showCharactersCounter?: boolean;
  uppercase?: boolean;
};
type Emits = {
  'update:modelValue': [value: string];
  focus: [event: FocusEvent];
  keyup: [event: KeyboardEvent];
  blur: [event: FocusEvent];
  hasCapsLock: [value: boolean];
};
</script>

<script setup lang="ts">
const moneyMaskTokens = {
  '0': {
    pattern: /\d/,
    multiple: true,
  },
  '9': {
    pattern: /\d/,
    optional: true,
  },
} as const;

defineOptions({ inheritAttrs: false });
const props = withDefaults(defineProps<Props>(), {
  modelValue: '',
  useChangeEvent: false,
  debounceTime: 0,
  type: 'text',
  label: undefined,
  errorMessage: undefined,
  warningMessage: undefined,
  mask: null,
  maskTokens: undefined,
  hideIncrements: false,
  formatMoney: false,
  currency: undefined,
  fractionDigits: 0,
  as: 'input',
  showCharactersCounter: false,
  uppercase: false,
});
const emit = defineEmits<Emits>();
const slots = defineSlots<{
  default(props: {}): any;
  'leading-icon'(props: {}): any;
  'trailing-icon'(props: {}): any;
}>();
const attrs = useAttrs();

const { locale } = useI18n();
const { highlightError } = injectErrorContext();

const inputRef = ref<HTMLInputElement | null>(null);
const charactersCount = ref(0);
const moneyMask = computed(() => {
  let decimalPoint = '';
  let fractions = '';
  if (props.fractionDigits > 0) {
    fractions = '9'.repeat(props.fractionDigits);

    if (locale.value === 'cs') {
      decimalPoint = ',';
    } else {
      decimalPoint = '.';
    }
  }

  return `0${decimalPoint}${fractions}`;
});
const maskOptions = computed<MaskOptions>(() => ({
  mask: props.formatMoney ? moneyMask.value : props.mask,
  tokens: props.formatMoney ? moneyMaskTokens : props.maskTokens,
  ...(props.formatMoney
    ? {
        preProcess: (value: string) =>
          locale.value === 'cs' ? value.replace(/[\s]/g, '') : value.replace(/[$,]/g, ''),
        postProcess: (value: string) => {
          if (!value) return '';

          const sub =
            props.fractionDigits +
            1 -
            (value.includes(locale.value === 'cs' ? ',' : '.')
              ? value.length - value.indexOf(locale.value === 'cs' ? ',' : '.')
              : 0);

          const finalValue = (locale.value === 'cs'
            ? value.replace(',', '.')
            : value) as unknown as number;

          return Intl.NumberFormat(locale.value, {
            minimumFractionDigits: props.fractionDigits,
            maximumFractionDigits: props.fractionDigits,
          })
            .formatToParts(finalValue)
            .filter((part) => part.type !== 'literal' && part.type !== 'currency')
            .map((part) => part.value)
            .join('')
            .slice(0, props.fractionDigits > 0 ? (sub ? -sub : undefined) : undefined);
        },
      }
    : {}),

  ...(props.uppercase
    ? {
        preProcess: (val: string) => val.toUpperCase(),
      }
    : {}),
}));
const customAttrs = computed<typeof attrs>(() => ({
  ...attrs,
  ...(props.as === 'textarea' ? { rows: 5 } : {}),
}));
const vOnEvents = computed(() => ({
  keypress: onKeyPress,
  focus: onFocus,
  blur: onBlur,
  input: onDebouncedInput,
  keyup: onKeyup,
  ...(props.useChangeEvent ? { change: onInput } : {}),
}));
const internalId = computed(() => (attrs.id as string | undefined) ?? uuid());
const canShowLeadingIcon = computed<boolean>(
  () => props.type === 'search' || !!slots['leading-icon'],
);
const canShowTrailingIcon = computed(() => !!slots['trailing-icon']);
const canShowLabel = computed(() => !!(props.label && props.label.trim().length > 0));
const canShowErrorMessage = computed(
  () => !!(props.hasError && props.errorMessage && props.errorMessage.length > 0),
);

function calculateCharactersCount(value: string) {
  charactersCount.value = value.length;
}
function onInput(event: Event & { target: { value: string } }) {
  if (props.showCharactersCounter) {
    calculateCharactersCount(event.target.value);
  }

  emit('update:modelValue', event.target.value);
}
const onDebouncedInput = debounce(onInput, props.debounceTime);
function onKeyPress(e: KeyboardEvent) {
  emit(
    'hasCapsLock',
    (e.getModifierState && e.getModifierState('CapsLock')) ||
      (e.key.toLowerCase() !== e.key.toUpperCase() && e.key === e.key.toUpperCase() && !e.shiftKey),
  );
}
function onFocus(event: FocusEvent) {
  emit('focus', event);
}
function onBlur(event: FocusEvent) {
  emit('blur', event);
}
function onKeyup(event: KeyboardEvent) {
  emit('keyup', event);
}
function isEnabled(value?: string | unknown) {
  if (typeof value === 'undefined') return false;
  if (value === '') return true;
  if (value === 'false') return false;
  if (value === 'true') return true;
}

onMounted(() => {
  if (props.showCharactersCounter && props.modelValue) {
    calculateCharactersCount(props.modelValue);
  }
});

defineExpose({ input: inputRef });
</script>

<template>
  <label class="text-field__container">
    <div
      class="text-field"
      :class="{
        [`text-field--${theme}`]: true,
        'text-field--disabled': isEnabled(attrs.disabled),
        'text-field--invalid': hasError,
        'text-field--warning': hasWarning,
        'text-field--with-leading-icon': canShowLeadingIcon,
        'text-field--with-trailing-icon': canShowTrailingIcon,
        'text-field--without-label': !canShowLabel,
        'text-field--has-validation': hasValidation,
        'text-field--hide-increments': hideIncrements,
      }"
    >
      <div v-if="canShowLeadingIcon" class="text-field__icon text-field__icon--leading">
        <svg
          v-if="type === 'search'"
          aria-hidden="true"
          width="18"
          height="18"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="m12 12 7 7M14 7.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0Z"
            stroke="currentColor"
            stroke-width="1.5"
          />
        </svg>

        <slot name="leading-icon" />
      </div>

      <div v-if="canShowTrailingIcon" class="text-field__icon text-field__icon--trailing">
        <slot name="trailing-icon" />
      </div>

      <component
        :is="as"
        :id="internalId"
        ref="inputRef"
        v-bind="customAttrs"
        v-maska="maskOptions"
        class="text-field__input"
        :aria-invalid="hasError"
        :aria-errormessage="hasError ? `${internalId}-error` : undefined"
        :value="modelValue"
        :type="type"
        v-on="vOnEvents"
      />

      <span class="text-field__label">
        {{ label }}{{ canShowLabel && isEnabled(attrs.required) ? '*' : '' }}
      </span>
    </div>

    <p
      v-if="canShowErrorMessage || hasWarning || (showCharactersCounter && $attrs.maxlength)"
      class="text-field__support"
    >
      <span
        v-if="canShowErrorMessage"
        :id="`${internalId}-error`"
        class="text-field__error"
        :class="{ 'error--highlight': highlightError }"
        v-html="errorMessage"
      />

      <span
        v-if="hasWarning"
        :id="`${internalId}-warning`"
        class="text-field__warning"
        v-html="warningMessage"
      />

      <span v-if="showCharactersCounter && $attrs.maxlength" class="text-field__character-counter">
        {{ `${charactersCount} / ${$attrs.maxlength}` }}
      </span>
    </p>
  </label>
</template>

<style lang="scss">
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input:-webkit-autofill-strong-password,
input:-webkit-autofill-strong-password-viewable {
  background-image: none !important; // FF
  transition: background-color 9999s ease-in-out 0s !important; // Chrome-based, Safari
  -webkit-background-clip: text !important;
}

.text-field__container {
  display: inline-flex;
  flex-direction: column;
}

.text-field {
  --box-shadow-color: var(--red-basic);
  --padding-top: 0.375rem;

  position: relative;

  padding-top: var(--padding-top);

  display: inline-block;

  overflow: hidden;

  &.text-field--without-label {
    padding-top: 0;

    .text-field__icon {
      top: 50%;
    }

    > input + span::before,
    > input + span::after,
    > textarea + span::before,
    > textarea + span::after {
      margin-top: 0;
    }
  }

  &--has-validation:only-child {
    // error message style + margin-top
    margin-bottom: calc(var(--font-size-base) * 1.66 * 0.8 + 0.1875rem);
  }

  &.text-field--hide-increments {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type='number'] {
      -moz-appearance: textfield;
      appearance: textfield;
    }
  }
}

.light .text-field:not(.text-field--dark),
.text-field--light {
  --border-color: var(--gray-light);
  --color: var(--gray-basic);
  --label-color: var(--gray-dark);

  &:hover:not(:has(input:disabled)) {
    --border-color: var(--black);
    --color: var(--black);
    --label-color: var(--black);
  }

  > input:not(:placeholder-shown),
  > textarea:not(:placeholder-shown) {
    --color: var(--black);
  }

  > input:-webkit-autofill,
  > input:-webkit-autofill:hover,
  > input:-webkit-autofill:focus,
  > input:-webkit-autofill:active,
  > input:-webkit-autofill-strong-password,
  > input:-webkit-autofill-strong-password-viewable {
    -webkit-text-fill-color: var(--black) !important;
  }

  &:focus-within {
    --color: var(--black);
  }

  > input:disabled {
    --color: var(--gray-basic);
  }

  &.text-field--disabled {
    --border-color: var(--gray-light);
    --color: var(--gray-light);
    --label-color: var(--gray-light);
  }
}

.dark .text-field:not(.text-field--light),
.text-field--dark {
  --border-color: var(--brown-lighter);
  --color: var(--brown-lighter);
  --label-color: var(--brown-lightest);

  &:hover:not(:has(input:disabled)) {
    --border-color: var(--white);
    --color: var(--white);
    --label-color: var(--brown-lightest);
  }

  &:focus-within {
    --color: var(--white);
  }

  > input:disabled {
    --color: var(--gray-lightest);
  }

  > input:not(:placeholder-shown),
  > textarea:not(:placeholder-shown) {
    color: var(--white);
  }

  > input:-webkit-autofill,
  > input:-webkit-autofill:hover,
  > input:-webkit-autofill:focus,
  > input:-webkit-autofill:active,
  > input:-webkit-autofill-strong-password,
  > input:-webkit-autofill-strong-password-viewable {
    -webkit-text-fill-color: var(--white) !important;
  }

  &.text-field--disabled {
    --border-color: var(--brown-light);
    --color: var(--brown-light);
    --label-color: var(--brown-light);
  }
}

.light .text-field:not(.text-field--dark),
.text-field--light,
.dark .text-field:not(.text-field--light),
.text-field--dark {
  &.text-field--warning {
    --box-shadow-color: var(--orange-basic);
    --border-color: var(--orange-basic);
    --color: var(--orange-basic);
    --label-color: var(--orange-basic);

    &:hover {
      --box-shadow-color: var(--orange-basic);
      --border-color: var(--orange-basic);
      --color: var(--orange-basic);
      --label-color: var(--orange-basic);
    }
  }

  &.text-field--invalid {
    --box-shadow-color: var(--red-neon-basic);
    --border-color: var(--red-neon-basic);
    --color: var(--red-neon-basic);
    --label-color: var(--red-neon-basic);

    &:hover {
      --border-color: var(--red-neon-basic);
      --color: var(--red-neon-basic);
      --label-color: var(--red-neon-basic);
    }
  }
}

.text-field > input,
.text-field > textarea {
  margin: 0;
  padding: 0.65rem 1rem;

  width: 100%;
  height: inherit;

  border: solid 0.0625rem; /* Safari */
  border-color: var(--border-color);
  border-top-color: transparent;
  border-radius: 0.375rem;
  background-color: transparent;

  color: var(--color);
  box-shadow: none; /* Firefox */
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  caret-color: var(--color);

  -webkit-text-fill-color: var(--color);
  -webkit-background-clip: text !important;
  background-clip: text !important;

  @media (prefers-reduced-motion: no-preference) {
    transition:
      border 0.2s,
      box-shadow 0.2s;
    will-change: border, box-shadow;
  }

  &::placeholder {
    color: inherit;

    @media (prefers-reduced-motion: no-preference) {
      transition: color 0.2s;
      will-change: color;
    }
  }
}

.text-field > textarea {
  display: block;

  resize: none;
}

.text-field--with-leading-icon {
  > input,
  > textarea {
    // original padding + icon's width + space between icon and text
    padding-left: calc(1rem + 1.5rem + 0.625rem);
  }

  .text-field__icon--leading {
    left: 1rem;
  }
}

.text-field--with-trailing-icon {
  > input,
  > textarea {
    // original padding + icon's width + space between icon and text
    padding-right: calc(1rem + 1.5rem + 0.625rem);
  }

  .text-field__icon--trailing {
    right: 1rem;
  }
}

.text-field > input[type='search'] {
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
}

.text-field > input + span,
.text-field > textarea + span {
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  width: 100%;
  max-height: 100%;

  border-color: var(--border-color);

  color: var(--label-color);
  font-size: 75%;
  line-height: 1;
  cursor: text;

  @media (prefers-reduced-motion: no-preference) {
    transition: color 0.2s;
    will-change: color;
  }
}

.text-field > input + span::before,
.text-field > input + span::after,
.text-field > textarea + span::before,
.text-field > textarea + span::after {
  content: '';

  display: block;

  margin-top: 0.375rem;
  border-top: solid 0.0625rem;
  border-top-color: var(--border-color);

  min-width: 0.8125rem;
  height: 0.5rem;

  box-shadow: inset 0 0.0625rem transparent;

  pointer-events: none;

  @media (prefers-reduced-motion: no-preference) {
    transition:
      border-color 0.2s,
      box-shadow 0.2s;
    will-change: border-color, box-shadow;
  }
}

.text-field > input + span::before,
.text-field > textarea + span::before {
  margin-right: 0.25rem;

  border-left: solid 0.0625rem transparent;
  border-radius: 0.375rem 0;
}

.text-field > input + span:empty::before,
.text-field > textarea + span:empty::before {
  margin-right: 0;
}

.text-field > input + span::after,
.text-field > textarea + span::after {
  flex-grow: 1;

  margin-left: 0.25rem;

  border-right: solid 0.0625rem transparent;
  border-radius: 0 0.375rem;
}

.text-field > input + span:empty::after,
.text-field > textarea + span:empty::after {
  margin-left: 0;
}

.text-field:hover > input,
.text-field:hover > textarea {
  border-color: var(--border-color);
  border-top-color: transparent;
}

.text-field:hover > input + span::before,
.text-field:hover > textarea + span::before,
.text-field:hover > input + span::after,
.text-field:hover > textarea + span::after {
  border-top-color: var(--border-color);
}

.text-field > input:focus,
.text-field > textarea:focus {
  border-color: var(--box-shadow-color);
  border-top-color: transparent;
  box-shadow:
    inset 0.0625rem 0 var(--box-shadow-color),
    inset -0.0625rem 0 var(--box-shadow-color),
    inset 0 -0.0625rem var(--box-shadow-color);
  outline: none;
}

.text-field > input:focus + span::before,
.text-field > input:focus + span::after,
.text-field > textarea:focus + span::before,
.text-field > textarea:focus + span::after {
  border-top-color: var(--box-shadow-color) !important;
  box-shadow: inset 0 0.0625rem var(--box-shadow-color);
}

.text-field > input:disabled,
.text-field > input:disabled + span,
.text-field > textarea:disabled,
.text-field > textarea:disabled + span {
  border-color: var(--border-color) !important;
  border-top-color: transparent !important;

  color: var(--color);
  pointer-events: none;
  -webkit-text-fill-color: var(--color); /* Override iOS / Android font color change */
}

.text-field > input:disabled + span::before,
.text-field > input:disabled + span::after,
.text-field > textarea:disabled + span::before,
.text-field > textarea:disabled + span::after {
  border-top-color: var(--border-color) !important;
}

.text-field > input:disabled:placeholder-shown + span,
.text-field > textarea:disabled:placeholder-shown + span {
  border-top-color: var(--border-color) !important;
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .text-field > input,
    .text-field > input::placeholder,
    .text-field > input + span,
    .text-field > textarea,
    .text-field > textarea::placeholder,
    .text-field > textarea + span,
    .text-field > input + span::before,
    .text-field > input + span::after,
    .text-field > textarea + span::before,
    .text-field > textarea + span::after,
    .text-field__icon {
      transition-duration: 0.1s;
    }
  }
}

.text-field__icon {
  position: absolute;
  top: calc(50% + (var(--padding-top) / 2));

  display: flex;
  justify-content: center;
  align-items: center;

  width: 1.5rem;
  height: 1.5rem;

  color: var(--color);
  font-size: var(--font-size-xs);

  transform: translateY(-50%);

  pointer-events: none;

  svg {
    width: 1.125rem;
    height: 1.125rem;
    aspect-ratio: 1 / 1;
  }

  @media (prefers-reduced-motion: no-preference) {
    transition: color 0.2s;
    will-change: color;
  }

  .text-field--light & {
    svg {
      color: var(--black);
    }
  }

  .text-field--dark & {
    svg {
      color: var(--white);
    }
  }
}

.text-field__support {
  display: inline-flex;
  gap: 0.1875rem 0.5rem;
  justify-content: space-between;

  margin: 0.1875rem 0.25rem 0 1rem;

  min-height: calc(var(--font-size-base) * 1.66 * 0.8);

  font-size: 75%;
  line-height: 1.66;
  letter-spacing: 0.03333em;
}

.text-field__error {
  color: var(--red-neon-basic);
}

.text-field__warning {
  color: var(--orange-basic);
}

.text-field__character-counter {
  margin-left: auto;

  color: var(--gray-basic);
}
</style>
